body, html, #root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #fff;
  color: #000;
  font-family: 'Press Start 2P', cursive;
}

.App {
  text-align: center;
}

.floating-images {
  position: relative;
  width: 800px;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.floating {
  animation: float 10s infinite;
}

.pixelated-world {
  padding: 20px;
  width: 90%;
}

.pixelated-world h1 {
  margin: 20px 0;
  font-size: 1.5em;
}

.pixelated-world p {
  margin: 10px 0;
  font-size: 1em;
}

.pixelated-world .social-icons {
  margin-top: 20px;
}

.pixelated-world .social-icons a {
  text-decoration: none;
}

@keyframes float1 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, 50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float2 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-50px, -50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float3 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, -50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float4 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-50px, 50px); }
  100% { transform: translate(0, 0); }
}

@keyframes pixelate {
  0% {
    filter: none;
    opacity: 1;
  }
  100% {
    filter: pixelate(10px);
    opacity: 0;
  }
}

@keyframes pixelate-in {
  0% {
    filter: pixelate(10px);
    opacity: 0;
  }
  100% {
    filter: none;
    opacity: 1;
  }
}

.pixelate-animation {
  animation: pixelate 2s steps(10) forwards;
}

@media (max-width: 600px) {
  body, html, #root {
    font-size: 0.8em; /* Adjust font size for mobile */
  }

  .pixelated-world h1 {
    font-size: 1.2em; /* Adjust h1 font size for mobile */
  }

  .pixelated-world p {
    font-size: 0.9em; /* Adjust paragraph font size for mobile */
  }

  .social-icons img {
    width: 30px; /* Adjust social icon size for mobile */
    height: 30px;
  }
}