@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

body, html, #root {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background: #fff;
  color: #000;
  font-family: 'Press Start 2P', cursive;
}

button {
  padding: 10px 20px;
  font-size: 1em;
  cursor: pointer;
  background: #000;
  border: none;
  color: #fff;
  transition: background 0.3s;
  margin-top: 20px;
  font-family: 'Press Start 2P', cursive;
  z-index: 1000;
}

button:hover {
  background: #444;
}

.pixelated {
  image-rendering: pixelated;
  image-rendering: crisp-edges;
}

.floating-images img {
  position: absolute;
  width: 20%;
  height: 20%;
}

@keyframes float1 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, 50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float2 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-50px, -50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float3 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(50px, -50px); }
  100% { transform: translate(0, 0); }
}

@keyframes float4 {
  0% { transform: translate(0, 0); }
  50% { transform: translate(-50px, 50px); }
  100% { transform: translate(0, 0); }
}

@keyframes pixelate {
  0% {
    filter: none;
    opacity: 1;
  }
  100% {
    filter: pixelate(100px);
    opacity: 0;
  }
}

@keyframes pixelate-in {
  0% {
    filter: pixelate(100px);
    opacity: 0;
  }
  100% {
    filter: none;
    opacity: 1;
  }
}

.pixelated-world {
  text-align: center;
  animation: pixelate-in 0.5s steps(10) forwards;
}

.social-icons img {
  width: 80px;
  height: 80px;
  margin: 0 20px;
}

.pixelate-animation {
  animation: pixelate 2s steps(10) forwards;
}
